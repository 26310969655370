import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/contacts", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/contacts/${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(data) {
        try {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("phone", data.phone);
            formData.append("message", data.message);
            // formData.append("comment", data.comment);
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(this.#baseUrl + "/contacts", formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    async attendContact(data) {
        try {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("attended", data.attended);
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(this.#baseUrl + '/register-attended/contacts', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }

    // Pushes posts to the server when called.
    updateRecord(id, formData) {
        let result = null;
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        axios.put(`${this.#baseUrl}/contacts/${id}`, formData, this.header)
            .then(response => {
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    deleteRecord(id) {
        let result = null;
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        axios.delete(`${this.#baseUrl}/contacts/${id}`, this.header)
            .then(response => {
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            valueError = error.message;
        }
        return valueError;
    }
}